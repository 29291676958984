import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { AspectRatio } from 'react-aspect-ratio';
import { replaceDomain } from "../../core-nextv3/util/util";
interface ImageSetProps {
    image : {
        name: string;
        id: string;
        type: string;
        _url: string;
        _150x150: string;
        _300x300: string;
        _480x480: string;
        _1024x1024: string;
        _1920x1920: string;
    };
    width?: number;
    aspectRatio?: number;
    className?: any;
    enabledGif?:any;
    domainFrom?:string;
    domainTo?:string;
    alt?:string;
    onClick?:any;
    priority?:boolean
}

export const ImageSet = ({
    image,
    width = 1920,
    aspectRatio = 2 / 3,
    enabledGif=false,
    domainFrom,
    domainTo,
    alt='',
    priority=false,
    className={},
}: ImageSetProps) => 
{
  const [imageData, setImageData] = useState<any>();

  useEffect(() => 
  {
      if(image)
      {
          setImageData(image)
      }
  }, [image])

  const placeholder = `/assets/product_placeholder.png`;

  const imageLoader = () => 
  {
      let url = encodeURI(image?._url) || placeholder;

      if(enabledGif && url.toLowerCase().match(/\.(gif)/g))
      {
           return url;
      }
      else if(width <= 480 && (image?._480x480 || image?._300x300)) 
      {
          url = image?._480x480 ? encodeURI(image?._480x480) : encodeURI(image?._300x300);        
      }
      else if(width <= 1024 && image?._1024x1024) 
      {
          url = encodeURI(image?._1024x1024);
      }
      else if(width <= 1920 && image?._1920x1920) 
      {
          url = encodeURI(image?._1920x1920);
      }
  
      url = url.replace('.webp', '.jpeg');

      if(url.indexOf('?') > -1)
      {
            url += 'auto=format';
      }
      else
      {
            url += '?auto=format';
      }
      
      url = replaceDomain(url, domainFrom, domainTo);      

      return url;
  };

  return (
    (imageData && <AspectRatio ratio={aspectRatio}>
        <Image
            loader={imageLoader}
            src={placeholder}
            className={className}
            sizes="100vw" 
            layout='fill'
            alt={alt}
            priority={priority}
            loading={priority ? 'eager' : 'lazy'}
        />
    </AspectRatio>) 
  );
};