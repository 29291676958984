import router from "next/router";
import { BsFacebook, BsInstagram, BsPinterest, BsYoutube } from "react-icons/bs";
import styles from "./styles.module.scss";
import { Newsletter } from "./Newsletter";
import useView from "../Hooks/useView";
import { Accordions } from "./Accordions";
import { FooterMenuDesk } from "./FooterMenuDesk";
import { Sac } from "./Sac";
import { PaymentsMethods } from "./PaymentMethods";
import { Certificates } from "./Certificates";
import { Bottom } from "./Bottom";
import { FadeInContent } from "./FadeInContent";

export const Footer = ({ account, homePage }: any) => 
{
  const { innerWidth = 0 } = useView();

  return (
    <footer className={styles.footer}>
      <Newsletter account={account} />
      {innerWidth <= 780 ? <Accordions /> : <FooterMenuDesk />}
      <Sac account={account} />
      <Bottom />
    </footer>
  );
};
