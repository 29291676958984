import { Matrix } from "../model/matrix"
import { hasStockByVariant } from "../stock/stock.util";
import Types from "../type";

const firstProductImage = (product:any, list:any=null, fildImage='images') =>
{
			if(product && product[fildImage])
			{		
					const imageTable = new Matrix(product[fildImage]);
					const data       = imageTable.getValueByListVariant(list);
			
					for(const key in data)
					{
							if(data[key])
							{				
									return data[key].images[0]
							}
					}			
			}

			return;
}

const secondProductImage = (product:any, list:any=null, fildImage='images') =>
{
	if(product && product[fildImage])
	{
		const imageTable = new Matrix(product[fildImage]);
		const data       = imageTable.getValueByListVariant(list);

		for(const key in data)
		{
			if(data[key])
			{				
				return data[key].images.length > 1 ? data[key].images[1] : data[key].images[0];
			}
		}			
	}

	return;
}

const firstProductImages = (product:any, fildImage='images') =>
{	
	if(product && product[fildImage])
	{
		// VARIANTE COMPLETA OU REDUZIDA
		const images = product[fildImage].data || product[fildImage];

		for(const key in images)
		{
			if(images[key])
			{				
				return images[key].images
			}
		}
	}
}

const firstSkuProductImageByColor = (product:any, color?: any, skuField='skuImages') =>
{
	if(product && product[skuField])
	{	
		const skuImages = product[skuField]?.data || product[skuField]  || {};

		for(const key in skuImages)
		{
			if(skuImages[key])
			{ 
				if(color && skuImages?.[color]?.images[0]) 
				{
					return true;
				}
			}
		}	
	}

	return false;
}

const skuProductItem = (product:any, variants: any[]) =>
{
	let sku = productCode(product, variants)

	if(variants)
	{
		for(let i = 0; i < variants.length; i++)
		{
			if(variants[i])
			{
				sku += '-' + variants[i].value;		
			}			
		}
	}
	else
	{
		if(product.variant)
		{
			for(const variant of product.variant)
			{
				sku += '-' + variant.value;
			}	
		}
	}
	
	return sku;
}

const firstVariantProductImage = (product:any) =>
{
	if(product)
	{
		for(const key in product.images)
		{
			if(product.images[key].images)
			{
				return product.images[key].images[0]
			}			
		}
	}
}

const secondVariantProductImage = (product:any) =>
{
	if(product)
	{
		for(const key in product.images)
		{
			if(product.images[key].images)
			{
				return product.images[key].images[1]
			}			
		}
	}
}

const firstVariantByLevel = (product:any, level:number) =>
{
	if(product && product.variant && product.variant[level])
	{
		for(const key in product.variant[level].items)
		{
			return product.variant[level].items[key];
		}
	}
	else
	{
		return null;
	}
}

const skuProductProduct = (product:any) =>
{
	let sku = product.code

	// if(product)
	// {
	// 	console.error(product.variant);

	// 	for(const variant of product.variant)
	// 	{
	// 		sku += '-' + variant.value;
	// 	}
	// }

	return sku;
}

const listItemsToListVariant = (list:any) =>
{
	const listVariant = [];

	if(list)
	{
		for(const ls of list)
		{
			if(ls.items.length > 0)
			{
				listVariant.push(ls.items[0]);
			}		
		}	
	}

	return listVariant;
}

const imageColorTableByVariant = (colorTable:any, variant:any) =>
{	
	for(const item of colorTable.table)
	{
		if(item.color.value == variant.value)
		{
			return item.image;
		}
	}
}

const productImages = (product: any, list:any=null, imageField='images') =>
{ 	
	if(product && product[imageField])
	{
		const imageTable = new Matrix(product[imageField]);
		const data       = imageTable.getValueByListVariant(list);

		for(const key in data)
		{
			if(data[key])
			{				
				return data[key].images || data[key][imageField];
			}
		}			
	}

	return;
}

const productVariantByValue = (product: any, variantValue: any, fieldVariant='variant') =>
{
	if(product && product[fieldVariant]) {
		for(const variant of product[fieldVariant])
		{
			for(const item of variant.items)
			{
				if(item.value == variantValue)
				{
					return item;
				}
			}
		}
	}	
}

const productVariantByLabel = (product: any, variantValue: any, fieldVariant='variant') =>
{
	if(product && product[fieldVariant]) {
		for(const variant of product[fieldVariant])
		{
			for(const item of variant.items)
			{
				if(item.value == variantValue)
				{
					return item;
				}
			}
		}
	}	
}

const productColorVariant = (query: any, colors: any) => 
{
	let variant : any = null;

	// COR
	if(query.color) 
	{		
		variant = colors?.filter
		(
			(color: any) => color.value == query.color
		);
	}

	return variant;
};

const validateListVariant = (product:any, listVariant:any) => 
{
	if(product.variant && product.variant.length > 0) 
	{		
		if(listVariant.length !== product.variant.length)
		{
			return false;
		}
		else
		{
			for(const item of listVariant)
			{
				if(!item)
				{
					return false;
				}
			}	

			return true;
		}
	}
	else
	{
		return true;
	}
};

const validateQuantityTable = (data:any) => 
{
	let quantity = 0;

	for(const key in data)
	{
		if(data[key] && data[key].quantity > 0)
		{
			quantity = data[key].quantity;
		}
	}

	return quantity > 0;
};

const getVarianteByValue = (value: any, items: any) => 
{
	let item : any = null;

	// COR
	if(value) 
	{		
		items = items?.filter
		(
			(item: any) => value == item.value
		);

		if(items && items.length > 0) {
			return items[0]
		}
	};	

	return item;
};

const productHasVariant = (product:any) => 
{	
	return product.variant && product.variant.length > 0;
};

const productParseQuery =  (query: any, categories:any, colors: any, sizes: any, brands?:any, baths?:any) => 
{
	const filters: any = [];

	if(!query) 
	{
	  	return filters;
	}	
    
	// CATEGORIAS
	if((query.category || query.slug) && categories) 
	{  
		for(const item of categories) 
		{
			if(item?.name?.toLowerCase() == query?.category?.toLowerCase() ||
			   (typeof query?.slug == 'string' && item?.slug?.toLowerCase() == query?.slug?.toLowerCase()) ||
			   (typeof query?.slug == 'object' && item?.slug?.toLowerCase() == query?.slug[0]?.toLowerCase()))
			{
				if(query.children && item._children)
				{
					for(const item2 of item._children)
					{
						if(item2.name == query.children ||
						   (typeof query?.slug == 'string' && item2?.slug?.toLowerCase() == query?.slug?.toLowerCase()) ||
						   (typeof query?.slug == 'object' && item2?.slug?.toLowerCase() == query?.slug[1]?.toLowerCase()))
						{
							filters.push({
								field: "indexes.categoriesxcolorxsize",
								operator: "combine",
								value: [
								{
									referencePath: item2.referencePath,
								},
							]});
							break;	
						}
					}
				}
				else
				{
					filters.push({
						field: "indexes.categoriesxcolorxsize",
						operator: "combine",
						value: [
						{
							referencePath: item.referencePath,
						},
					]});	
					break;
				}
			}					
		}	  
	}	
  
	// COR
	if(query.color) 
	{
		const colorValue = colors?.filter
		(
			(color: any) => color.value == query.color
		);
	
		filters.push({
			field: "indexes.categoriesxcolorxsize",
			operator: "combine",
			value: [...colorValue],
		});
	}
  
	// TAMANHO
	if(query.size) 
	{
		const sizeValue = sizes?.filter(
			(size: any) => size.value == query.size
	  	);

		  filters.push({
			field: "indexes.categoriesxcolorxsize",
			operator: "combine",
			value: [...sizeValue],
	  	});
	}
  
	// BANHO
	if(query.bath) 
	{
		const bathValue = baths?.filter(
			(bath: any) => bath.value == query.bath
	  	);

		  filters.push({
			field: "indexes.categoriesxcolorxsize",
			operator: "combine",
			value: [...bathValue],
	  	});
	}

	// PRECO MIN
	if(query.precoMin && parseInt(query.precoMin) > 0) 
	{
		filters.push({
			field: "indexes.price",
			operator: ">=",
			value: Number(query.precoMin)
		});
	}

	// PRECO MAX
	if(query.precoMax && parseInt(query.precoMax) > 0) 
	{
		filters.push({
			field: "indexes.price",
			operator: "<",
			value: Number(query.precoMax)
		});
	}

	// TERM
	if(query.term) 
	{
		filters.push({
			field	 : "search",
			operator : "array-contains",
			value	 : query.term.toLowerCase()
		});
	}

	// MARCAS
	if(query.brand && brands) 
	{  
		for(const item of brands)
		{
			if(item.name == query.brand)
			{
				filters.push({
					field: "brand",
					operator: "==",
					value: 
					{
						referencePath: item.referencePath,
					},
				});
				break;
			}					
		}	  
	}

	return filters;
};

const getCategoryByQuery =  (query: any, categories:any) => 
{
	// CATEGORIAS
	if(query.category && categories) 
	{  
		for(const item of categories)
		{
			if(item.name.toLowerCase() == query.category.toLowerCase())
			{
				if(query.children && item._children)
				{
					for(const item2 of item._children)
					{
						if(item2.name == query.children)
						{
							return item2;	
						}
	
					}
				}
				else
				{
					return item;	
				}
			}					
		}	  
	}
};

const getBrandByQuery =  (query: any, brands:any) => 
{
	// MARCAS
	if(query.brand && brands) 
	{  
		for(const item of brands)
		{
			if(item.name == query.brand)
			{
				return item;
			}					
		}	  
	}
};

const categorieToOption = (category:any) => 
{
	if(category)
	{
		return {
			label : category.name,
			value : category.id,
			slug  : category.slug,
		}
	}

	return;
};

const categoriesToOptions = (categories:any) => 
{
	  if(categories)
	  {
		  	const list = [];

			for(const item of categories)
			{
				list.push({
					label : item.name,
					value : item.id,
					slug  : item.slug,
					data  : item
				})
			}

			return list;
	  }

	  return categories;
};

const brandToOptions = (brands:any) => 
{
	  if(brands)
	  {
		  	const list = [];

			for(const item of brands)
			{
				list.push({
					label : item.name,
					value : item.name,
				})
			}

			return list;
	  }

	  return brands;
};

const sortArray = (items:any, field:string = 'label', asc=true) => 
{
	  if(items)
	  {
			items.sort(( a:any, b:any ) => 
			{
				if(a[field] < b[field])
				{
					return asc ? -1 : 1;
				}
				
				if(a[field] > b[field])
				{
					return asc ? 1 : -1;
				}

				return 0;
			});
	  }

	  return items;
};

const productCode = (product:any, list:any=null) =>
{
	let value : any = 0;

	if(product)
	{
		if(product.code)
		{
			value = product.code;
		}
		else if(product?.codeTable?.data)
		{
			if(!product.codeTable.variant)
			{
				product.codeTable.variant = product.variant
			}

			const codeTable = new Matrix(product.codeTable);
			const data      = codeTable.getValueByListVariant(list);

			for(const key in data)
			{
				value = data[key].code;
				break;
			}					
		}			
	}

	return value;
}

const parserImagesListVariantToQuery = (list:any) =>
{
	let query = '';

	if(list)
	{
		for(const key in list)
		{
			query += list[key]?.type?.code + '=' + list[key].value;

			if(parseInt(key) < list.length  - 1)
			{
				query += '&';
			}
		}		
	}

	return query;
}

const getImagesListVariantByProduct = (product:any, list:any) =>
{
	let imagesListVariant = [];

	if(product?.variant)
	{
		for(const variant of list)
		{
			for(const item of product?.variant)
			{
				for(const item2 of item.items)
				{
					if(item?.type?.skuImage && variant?.value == item2.value)
					{
						imagesListVariant.push(variant);
						break;
					}
				}
			}
		}				
	}

	return imagesListVariant;
}

const getVideosListVariantByProduct = (product:any, list:any) =>
{
	let videosListVariant = [];

	if(product?.variant)
	{
			for(const variant of list)
			{
					for(const item of product?.variant)
					{
							for(const item2 of item.items)
							{
									if(item?.type?.skuVideo && variant?.value == item2.value)
									{
											videosListVariant.push(variant);
											break;
									}
							}	
					}
			}				
	}

	return videosListVariant;
}

const getListVariantBySkuImages = (listVariant:any) =>
{
	let imagesListVariant = [];

	if(listVariant)
	{
		for(const item of listVariant)
		{
			for(const item2 of item.items)
			{
				if(item?.type?.skuImage)
				{
					imagesListVariant.push(item2);
					break;
				}
			}
		}
	}

	return imagesListVariant;
}

const parserQueryToImagesListVariant = (product:any, query:any, noSotck=false) =>
{
	let listVariant : any = [];

	if(product?.variant)
	{
		for(const item of product?.variant)
		{	
			let hasVariant = false;

			for(const key in query)
			{
				const variant = getVarianteByValue(query[key], item.items);

				if(variant)
				{
					listVariant.push(variant);	
					hasVariant = true;
				}				
			}

			if(!hasVariant)
			{
				for(const variant of item.items)
				{
					if(hasStockByVariant(product, variant) || !noSotck)
					{
						listVariant.push(variant);
						break;
					}
				}							
			}
		}
	}

	return listVariant;
}

const nameWithVariant = (title:string, list:any) =>
{

	if(list)
	{
		for(const variant of list)
		{
			if(variant && variant.label)
			{
				title += ' ' + variant.label 
			}			
		}				
	}

	return title;
}

const adjustVariant = (itemsVariant:any) =>
{
	if(itemsVariant.length == 0)
	{
		itemsVariant = Types.VARIANT_DEFAULT;
	}

	return itemsVariant;
}

const setTypeinItemsVariant = (itemsVariant:any) =>
{
	if(itemsVariant && itemsVariant.length > 0)
	{
		for(const item of itemsVariant)
		{
			if(item.type)
			{
				for(const item2 of item.items)
				{
					item2.type = item.type;
				}
			}
		}
	}

	return itemsVariant;
}

export { 
	nameWithVariant,
	parserImagesListVariantToQuery,
	parserQueryToImagesListVariant,
	getImagesListVariantByProduct,
	firstProductImage, 
	firstProductImages,
	firstVariantProductImage, 
	skuProductItem,
	productVariantByValue,
	firstVariantByLevel,
	productImages,
	productCode,
	productParseQuery, 
	productVariantByLabel, 
	productColorVariant, 
	getCategoryByQuery,
	getBrandByQuery,
	sortArray,
	categoriesToOptions,
	imageColorTableByVariant,
	secondVariantProductImage,
	secondProductImage,
	firstSkuProductImageByColor,
	skuProductProduct,
	getVarianteByValue,
	productHasVariant,
	categorieToOption,
	listItemsToListVariant,
	validateListVariant,
	brandToOptions,
	validateQuantityTable,
	adjustVariant,
	getListVariantBySkuImages,
	setTypeinItemsVariant,
	getVideosListVariantByProduct
}


