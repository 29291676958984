import TagManager from "react-gtm-module";
import sha256 from "js-sha256";
import slugify from "slugify";
import Cookies from 'js-cookie';
import { productCode, skuProductItem } from "../product/product.util";
import { productPrice, productPromotionalPrice } from "../price/price.util";
import { TDate } from "../model/TDate";

export const tagManager4 =
{
	initialize: (gtm: any) => {
		const tagManagerArgs =
		{
			gtmId: gtm.key,
		};

		TagManager.initialize(tagManagerArgs);
	},
	pageView: (
		path: string, // /produto/abs-123
		title: string, // Produto Legal
		area: string, // b2b
		segment: string, // ecommerce
		// category, // roupas
		// subCategory, // vestidos
		environment: string, // app | desktop | mobile
		platform: string, // binext 1.0
		// type = '', // home|erro|atendimento
		user: any) => // usuário logado
	{
		const data: any =
		{
			event: 'pageView',
			page:
			{
				pagePath: path,
				name: title,
				area: area,
				segment: segment,
				// category: category,
				// subCategory: subCategory,
				environment: environment,
				platform: platform,
				// type: type
			},

		};
		if (user) {
			data.user = tagManager4.parseUser(user);
		}
		TagManager.dataLayer(
			{
				dataLayer: data
			});
	},
	signUp: (method: string = "undefined") => {
		const data: any =
		{
			event: 'sign_up',
			ecommerce: {
				method: method,
			},
			facebook_ecommerce:
			{
				status: true
			}
		};
		TagManager.dataLayer(
			{
				dataLayer: data
			});
	},
	lead: (method: string = "undefined") => {
		const data: any =
		{
			event: 'lead',
			ecommerce: {
				currencyCode: 'BRL',
				value: 0
			},
			facebook_ecommerce:
			{
				content_name: method
			}
		};
		TagManager.dataLayer(
			{
				dataLayer: data
			});
	},
	registerEvent: (event: string, component: string, name: string, target = 0, user: any) => {
		const data: any =
		{
			event: 'interaction',
			interaction:
			{
				component: slugify(component, { lower: true }), //menu, cart, etc
				action: slugify(event, { lower: true }), //click, modal, etc
				description: slugify(name || '', { lower: true }),
				value: (target + '').toLowerCase()
			}
		};
		if (user) {
			data.user = tagManager4.parseUser(user);
		}
		TagManager.dataLayer(
			{
				dataLayer: data
			});
	},
	registerNonInteractiveEvent: (event: string, component: string, name: string, target = 0, user: any) => {
		const data: any =
		{
			event: 'noninteraction',
			interaction:
			{
				component: slugify(component, { lower: true }), //menu, cart, etc
				action: slugify(event, { lower: true }), //click, modal, etc
				description: slugify(name, { lower: true }),
				value: (target + '').toLowerCase()
			}
		};
		if (user) {
			data.user = tagManager4.parseUser(user);
		}
		TagManager.dataLayer(
			{
				dataLayer: data
			});
	},
	viewBanner: (banner: any, position = 0) => {
		if (!banner.image) {
			banner.image = banner.desktop;
		}
		let filename = banner.image.name.split('.');
		filename.pop();
		filename = filename.join('.');
		TagManager.dataLayer(
			{
				dataLayer:
				{
					event: 'promotionView',
					ecommerce:
					{
						promoView:
						{
							promotions:
								[
									{
										id: banner.id,
										name: slugify(banner.name ? banner.name : filename, { lower: true }),
										creative: filename,
										position: position
									}
								]
						}
					}
				}
			});
	},
	clickBanner: (banner: any, user = null) => {
		if (!banner.image) {
			banner.image = banner.desktop;
		}
		let filename = banner.image.name.split('.');
		filename.pop();
		filename = filename.join('.');
		TagManager.dataLayer(
			{
				dataLayer:
				{
					event: 'promotionClick',
					ecommerce:
					{
						promoClick:
						{
							promotions:
								[
									{
										id: banner.id,
										name: slugify(banner.name ? banner.name : filename, { lower: true }),
										creative: filename,
										position: banner.order
									}
								]
						}
					}
				}
			});
		tagManager4.registerEvent('click-link', 'banner', banner.name ? banner.name : filename, 0, user);
	},
	productList: (products: Array<any>) => {
		const productList: Array<any> = [];
		const fbProductList: Array<any> = [];
		products.forEach((product) => {
			productList.push(tagManager4.parse(product));
			fbProductList.push(tagManager4.facebookParse(product));
		});
		TagManager.dataLayer(
			{
				dataLayer:
				{
					event: "productImpression",
					ecommerce:
					{
						currencyCode: 'BRL',
						items: productList
					},
					pageTitle: "Produtos",
					facebook_ecommerce:
					{
						items: fbProductList,
					},
				},
			});
	},
	productClick: (product: any, selector: string) => {
		TagManager.dataLayer(
			{
				dataLayer:
				{
					event: "productClick",
					ecommerce:
					{
						click:
						{
							actionField: { list: [selector] },
							products: [tagManager4.newParse(product)]
						}
					},
					facebook_ecommerce:
					{
						items: [tagManager4.facebookParse(product)],
					},
				},
			});
	},
	productView: (product: any, selector: string, user: any = null, variant: any = null) => {
		let unavailable = false;
		if (product.stockTable) {
			unavailable = true;
			const keys = Object.keys(product.stockTable.data);
			for (const key of keys) {
				if (product.stockTable.data[key].quantity > 0) {
					unavailable = false;
					break;
				}
			}
		}
		if (unavailable) {
			tagManager4.registerNonInteractiveEvent('view-product', 'page', 'produto-indisponivel:' + product.code + ":" + product.name, product.price, user)
		}
		else {
			TagManager.dataLayer(
				{
					dataLayer:
					{
						event: "productDetail",
						ecommerce:
						{
							items: [tagManager4.parse(product, null, variant)],
							currency: 'BRL',
							value: productPrice(product, null, false)
						},
						facebook_ecommerce:
						{
							content_ids: [tagManager4.facebookParse(product, null, variant)],
						},
						dynamic_remarketing: {
							value: productPrice(product, null, false),
							items: [tagManager4.remarketingParse(product, variant)]
						},
						...(user && { user: tagManager4.parseUser(user) })
					}
				});
		}
	},
	addToWishlist: (product: any, user: any = null, variant: any = null) => {
		TagManager.dataLayer(
			{
				dataLayer:
				{
					event: "add_to_wishlist",
					ecommerce: {
						currency: 'BRL',
						value: productPrice(product, null, false),
						items: [tagManager4.parse(product, null, variant)],
					},
					facebook_ecommerce:
					{
						content_name: product.name,
						content_ids: [tagManager4.facebookParse(product, null, variant)],
						content_type: 'product'
					},
					...(user && { user: tagManager4.parseUser(user) })
				},
			});
	},
	customList: (list: Array<any>, event: string) => {
		TagManager.dataLayer(
			{
				dataLayer:
				{
					event: event,
					ecommerce:
					{
						currencyCode: 'BRL',
						impressions: list
					}
				},
			});
	},
	customClick: (item: any, selector: string, event: string) => {
		TagManager.dataLayer(
			{
				dataLayer:
				{
					event: event,
					ecommerce:
					{
						click:
						{
							actionField: { list: [selector] },
							items: [item]
						}
					},
				},
			});
	},
	customView: (item: any, category: string, event: string, user: any = null) => {
		TagManager.dataLayer(
			{
				dataLayer:
				{
					event: event,
					ecommerce:
					{
						detail:
						{
							actionField: { list: [slugify(category, { lower: true })] },
							items: [item]
						}
					}
				},
			});
	},
	cartView: (
		cart: any,
		path: string, // /produto/abs-123
		title: string, // Produto Legal
		area: string, // b2b
		segment: string, // ecommerce
		environment: string, // app | desktop | mobile
		user: any) => // usuário logado) => 
	{
		const productList = [];
		const remarketingtList = [];
		const cartTotal = [];
		if (cart.items) {
			for (const product of cart.items) {
				productList.push(tagManager4.parse(product));
				remarketingtList.push(tagManager4.remarketingParse(product));
			}
		}


		const data: any =
		{
			event: "view_cart",
			ecommerce: {
				currency: "BRL",
				value: cart.total,
				items: productList,
			},
			dynamic_remarketing: {
				value: cart.total,
				items: remarketingtList
			}
		};

		if (user) {
			data.user = tagManager4.parseUser(user);
		}

		TagManager.dataLayer(
			{
				dataLayer: data
			});
	},

	checkoutStep: (cart: any, event: string, step: number, user: any, additionalData: any[] = []) => {
		if (!cart.items) {
			return;
		}

		const productList = [];
		const fbProductList = [];
		const remarketingList = [];
		let num_items = null;
		for (const product of cart.items) {
			num_items += product.quantity
			productList.push(tagManager4.parse(product));
			fbProductList.push(tagManager4.facebookParse(product));
			remarketingList.push(tagManager4.remarketingParse(product));
		}
		const data: any =
		{
			event: event,
			ecommerce:
			{
				currency: "BRL",
				value: cart.total,
				coupon: cart.coupon ? cart.coupon._code : '',
				items: productList,
			},
			facebook_ecommerce:
			{
				contents: fbProductList,
				content_type: "product",
				num_items: num_items,
				value: cart.total,
				currency: "BRL"
			},
			dynamic_remarketing: {
				value: cart.total,
				items: remarketingList
			},
			...(user && { user: tagManager4.parseUser(user) })

		};

		for (const extraData of additionalData) {
			data.ecommerce[extraData.key] = extraData.value;
		}

		if (user) {
			data.user = tagManager4.parseUser(user);
		}

		TagManager.dataLayer(
			{
				dataLayer: data
			});
	},
	addToCart: (pagePath: string, product: any, quantity: any, user: any) => {
		const price: any = productPrice(product, null, false);
		tagManager4.registerEvent('click-button', 'box', 'Adicionar ao carrinho:' + product.code + ":" + product.name, 0, user);

		TagManager.dataLayer(
			{
				dataLayer:
				{
					event: "addToCart",
					ecommerce:
					{
						currency: 'BRL',
						value: price,
						items: [tagManager4.parse(product, quantity, product.variant)],
					},
					facebook_ecommerce:
					{
						contents: [tagManager4.facebookParse(product, quantity, product.variant)],
						content_type: "product",
						currency: "BRL",
						value: price * quantity
					},
					dynamic_remarketing: {
						value: price,
						items: [tagManager4.remarketingParse(product, product.variant)],
					},
					...(user && { user: tagManager4.parseUser(user) })
				}
			});
	},
	removeFromCart: (pagePath: string, product: any, quantity: any, user:any) => {
		TagManager.dataLayer(
			{
				dataLayer:
				{
					event: "removeFromCart",
					ecommerce:
					{
						currency: 'BRL',
						value: productPrice(product, null, false),
						items: [tagManager4.parse(product, quantity)],
					},
				},
				...(user && { user: tagManager4.parseUser(user) })
			});
	},
	addProduct: (pagePath: string, product: any, user: any) => {
		TagManager.dataLayer(
			{
				dataLayer:
				{
					event: "view_item",
					pagePath: pagePath,
					pageTitle: "Produto",
					ecommerce:
					{
						items: tagManager4.parse(product),
					},
					facebook_ecommerce:
					{
						items: tagManager4.facebookParse(product),
					},
				},
				...(user && { user: tagManager4.parseUser(user) })
			});
	},
	addProducts: (pagePath: string, products: any, user: any) => {
		if (products && products.length > 0) {
			const gTagproducts = products.map((product: any) => {
				return tagManager4.parse(product);
			});
			const facebookProducts = products.map((product: any) => {
				return tagManager4.facebookParse(product);
			});
			TagManager.dataLayer(
				{
					dataLayer:
					{
						event: "view_item_list",
						ecommerce:
						{
							item_list_name: pagePath,
							items: gTagproducts,
						},
						facebook_ecommerce:
						{
							content_name: pagePath,
						},
						...(user && { user: tagManager4.parseUser(user) })
					},
				});
		}
	},
	checkout: (pagePath: string, products: any) => {
		if (products) {
			const gtagProducts = products.map((product: any) => {
				return tagManager4.parse(product);
			});
			const rmktProducts = products.map((product: any) => {
				return tagManager4.remarketingParse(product);
			});
			let subtotal = 0;
			let productAmount = 0;
			const facebookProducts = products.map((product: any) => {
				productAmount += product.quantity;
				subtotal += product.promotionalPrice
					? product.promotionalPrice * product.quantity
					: product.price * product.quantity;
				return tagManager4.facebookParse(product);
			});
			TagManager.dataLayer(
				{
					dataLayer:
					{
						event: "begin_checkout",
						ecommerce:
						{
							currency: 'BRL',
							value: subtotal,
							items: gtagProducts,
						},
						facebook_ecommerce:
						{
							contents: facebookProducts,
							currency: "BRL",
							content_type: 'product',
							num_items: productAmount,
							value: subtotal,
						},
						dynamicremarketing: {
							value: subtotal,
							items: rmktProducts
						},
					},
				});
		}
	},
	purchase: (order: any, user:any) => 
	{
		if(!order.items)
		{
			return;
		}

		const gtagProducts = order.items.map((product: any) => {
			return tagManager4.parse(product);
		});
		const facebookProducts = order.items.map((product: any) => {
			return tagManager4.facebookParse(product);
		});
		const remarketingProducts = order.items.map((product: any) => {
			return tagManager4.remarketingParse(product);
		});

		const totalMinusShipping = Math.round((order.total - order.totalShipping)* 100) / 100;

		TagManager.dataLayer(
			{
				dataLayer:
				{
					event: "purchase",
					ecommerce:
					{
						purchase:
						{
							currency: "BRL",
							transaction_id: order.id,
							value: order.total,
							coupon: order.coupon ? order.coupon._code : '',
							shipping: order.totalShipping,
							items: gtagProducts,
						},
						facebook_ecommerce:
						{
							contents: facebookProducts,
							content_type: 'product',
							currency: "BRL",
							value: totalMinusShipping >= 0 ? totalMinusShipping : 0,
						},
						dynamic_remarketing: {
							value: order.total,
							items: remarketingProducts
						}
					},
					...(user && { user: tagManager4.parseUser(user) })
				}
			});
	},
	newParse: (product: any, quantity = 1) => {
		const item =
		{
			name: product.name ? product.name : product.product.name,
			id: product.id,
			originalPrice: productPrice(product, null, false),
			price: productPromotionalPrice(product, null, false),
			brand: product.store ? product.store.name : '',
			category: product.categories && product.categories.length > 0 ? product.categories[0].name : "",
			list: "Produtos",
			position: product.order ? product.order : 1,
			quantity: quantity ? quantity : product.quantity,
		};
		return item;
	},
	parse: (product: any, quantity = null, variant: any = null) => {

		let showVariant = false;
		let itemID;

		if (Array.isArray(variant) && variant.length) {
			showVariant = true;
		}

		if (Array.isArray(product.variant) && product.variant.length) {
			showVariant = true;
		}

		itemID = skuProductItem(product, variant);

		const item =
		{
			item_name: product.name ? product.name : product.product ? product.product.name : "",
			item_id: variant ? itemID : product.id,
			//item_variant: variant ? variant[0].label : typeof product.variant !== "undefined"? product.variant[0].label: "",
			...(showVariant && { item_variant: variant && variant.length > 0 ? variant[0].label : product.variant[0].label }),
			price: productPrice(product, null, false),
			item_category: product.categories && product.categories.length > 0 ? product.categories[0].name : "",
			item_list_name: "Produtos",
			quantity: quantity ? quantity : product.quantity,
		};
  
		//console.error('xxx', item);

		return item;
	},
	facebookParse: (product: any, quantity = null, variant: any = null) => {
		const item =
		{
			id: variant ? skuProductItem(product, variant) : product.id,
			quantity: quantity ? quantity : product.quantity,
		};
		return item;
	},
	remarketingParse: (product: any, variant: any = null) => {
		const item =
		{
			id: variant ? skuProductItem(product, variant) : product.id,
			google_business_vertical: "retail",
		};
		return item;
	},
	parseUser: (user: any) => {
		//console.error(user);
		const data: any =
		{
			id: user.id,
			name: sha256.sha256(user.name + ",s)S.X-p;SdwsA2&lR.dIy|SCg}bZ1{&7y^%kpk2u9V+{mEO%n3HccBYJIKhFujb"),
			email: sha256.sha256(user.email + ",s)S.X-p;SdwsA2&lR.dIy|SCg}bZ1{&7y^%kpk2u9V+{mEO%n3HccBYJIKhFujb"),
			msisdn: sha256.sha256(user.phone + ",s)S.X-p;SdwsA2&lR.dIy|SCg}bZ1{&7y^%kpk2u9V+{mEO%n3HccBYJIKhFujb"),
			type: 'cadastrado',
			phone: sha256.sha256(user.phone?.replace(/\(|\)| |-/g, '') + ",s)S.X-p;SdwsA2&lR.dIy|SCg}bZ1{&7y^%kpk2u9V+{mEO%n3HccBYJIKhFujb"),
			birthdate: user.birthday ? sha256.sha256(new TDate({ value : user.birthday }).format('yyyyMMdd') + ",s)S.X-p;SdwsA2&lR.dIy|SCg}bZ1{&7y^%kpk2u9V+{mEO%n3HccBYJIKhFujb") : '',
			gender: user.gender ? sha256.sha256(user?.gender?.value?.toLowerCase() + ",s)S.X-p;SdwsA2&lR.dIy|SCg}bZ1{&7y^%kpk2u9V+{mEO%n3HccBYJIKhFujb") : '',
			//cookies do facebook, não sabemos se é assim
			fbp: Cookies.get('_fbp'),
			fbc: Cookies.get('_fbc'),
			isLoggedIn: true
		}
		if (user.address) {
			data.address =
			{
				city: user.address.city,
				state: user.address.state,
				postalCode: user.address.zipcode,
				country: 'BRA'
			}
		}
		if (user.cpf && user.cpf != '') {
			data.cpf = sha256.sha256(user.cpf + ",s)S.X-p;SdwsA2&lR.dIy|SCg}bZ1{&7y^%kpk2u9V+{mEO%n3HccBYJIKhFujb");
		}
		else if (user.cnpj) {
			data.cnpj = sha256.sha256(user.cnpj + ",s)S.X-p;SdwsA2&lR.dIy|SCg}bZ1{&7y^%kpk2u9V+{mEO%n3HccBYJIKhFujb");
		}
		return data;
	}
};
