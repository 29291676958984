import styles from "./styles.module.scss";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { SearchBar } from "../SearchBar";
import { GiClothes } from "react-icons/gi";
import { MobileMenu } from "../MobileMenu";
import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { BsBag, BsPerson } from "react-icons/bs";
import { useCore } from "../../core-nextv3/core/core";
import { BiSearch, BiWalletAlt } from "react-icons/bi";
import ModalBag from '../../component-nextv3/ModalBag';
import { header_submenu_animation } from "../animation";
import { randomNumber, useSetUTMParameters } from "../../core-nextv3/util/util";
import { currencyMask } from "../../core-nextv3/util/mask";
import { MenuType } from '../../component-nextv3/MenuType';
import { useGetCart } from "../../core-nextv3/cart/cart.api";
import { AiOutlineHeart, AiOutlineUser } from "react-icons/ai";
import {useResponsive} from "../../core-nextv3/util/useResponsive";
import { useGetLoggedAuth } from "../../core-nextv3/auth/auth.api";
import { CarouselTopHeader } from "../../component-nextv3/CarouselTopHeader";
import { AUTH_SETTING, CART_SETTING, MENU_FOOTER_STATIC, THEME_SETTING } from "../../setting/setting";

export const Header = ({ categories, account, menu, topHeader, gateway }: any) => 
{
	const router                                  			                             = useRouter();	
	const location                                			                             = useRouter().pathname;
	const { pathname }                                                                   = useRouter();
	const { isDesktop }				              			                             = useResponsive();
	const [searchBar, setSearchBar]               			                             = useState(false);
	const [stickyHeader, setStickyHeader]         			                             = useState("normal");
	const [stickyHeaderNone, setStickyHeaderNone]         	                             = useState("fixed");
	const [isMobileMenuOpen, setIsMobileMenuOpen] 			                             = useState(false);
	const [isHeaderMenuOpen, setIsHeaderMenuOpen]                                        = useState<boolean>(false);
	const {cart, user, setUser, setCart, setInstallmentRule, isOpenCart, setIsOpenCart } = useCore();
	
    const toggleDrawer = () => {
        setIsHeaderMenuOpen((prevState) => !prevState)
    }

	const nonPathsHeaderStick: boolean = ["/produto/[slug]", "/produto-two/[slug]"].includes( pathname );
	
	//console.log('menu', menu)

	useGetLoggedAuth(AUTH_SETTING, (data: any) => 
	{
		setUser(data);
	});

	useGetCart(CART_SETTING, (data: any) => 
	{
		setCart(data);
	});

	useSetUTMParameters();
  
	const doLogin = () =>
	{
		if(user)
		{
			router.push("/perfil");
		}
		else
		{
			router.push("/login");
		}		
	}

	// useEffect(() => {

	// 	if(router.isReady)
	// 	{
			
	// 	}		

	// }, [router.isReady, router.asPath]);

	useEffect(() => 
	{			
		if(isDesktop)
		{
			window.addEventListener("scroll", () => 
			{
				 if(window.scrollY >= 100) 
				{
					setStickyHeader("sticky");
				}
				else 
				{
					setStickyHeader("normal");
				}
			});	
		}
	}, [isDesktop]);

	useEffect(() => 
	{			
		if(!isDesktop)
		{
			window.addEventListener("scroll", () => 
			{
				if(window.scrollY >= 1 && THEME_SETTING?.disabledHeaderSticke == true && nonPathsHeaderStick) 
				{
					setStickyHeaderNone("none");
				}
				else 
				{
					setStickyHeaderNone("fixed");
				}
			});	
		}		
	}, [!isDesktop]);

	useEffect(() =>
	{
		if(gateway && gateway.installmentRule)
		{
			setInstallmentRule(gateway.installmentRule);
		}
	}, [gateway])

	// const ref = useRef(null);
	// const refSticky = useRef(null);
	// const [menuProps, toggleMenu] = useMenuState({ transition: true });
	// const [stickyMenuProps, toggleStickyMenu] = useMenuState({ transition: true, });

	return ( 
		<>
			<div
				className={styles.header + ' ' + (account?.hasHeaderFixed ? styles.fixed : '')}
				style={
					location == "/"
						? {
							position: "absolute",
							background: "transparent",
							color: "#fff",
						}
						: {
							color: "#000",
						}
				}
			>
				{!THEME_SETTING.disabledHeaderInline && isDesktop &&
					<>
						{topHeader?.published === true && (
							<CarouselTopHeader topHeader={topHeader} />
						)}
						<div className={styles.content}>
							<div className={styles.top}>
								<div className={styles.tranparent} />
								<li>
									<a href="/" aria-label={account?.name}>
										{location == "/" ? (
											<>
												<img
													className={styles.logo + ' logo'}
													src="/assets/logo_white.png"
													alt={account?.name}												
												/>
												<img
													className={styles.logoColor + ' logo'}
													src="/assets/logo.png"
													alt={account?.name}
												/>
											</>
										) : (
											<img
												className={styles.logoOthers + ' logo'}
												src="/assets/logo.png"
												alt={account?.name}
											/>
										)}
									</a>								
								</li>
								<div className={styles.otherPages}>
									{MENU_FOOTER_STATIC && (MENU_FOOTER_STATIC.map((item: any) =>
									(
										<motion.li
											className={styles.menuItemGrid}
											initial="animate"
											animate="animate"
											whileHover="hover"
											key={randomNumber(8)}
										>
											{item.label}
											<motion.ul
												className={styles.subMenuGrid}
												variants={header_submenu_animation}
											>
												{item.children.map((item2: any) => (
													<p key={randomNumber(8)}>
														<a href={item2.url}>
															{item2.label}
														</a>
													</p>
												))}
											</motion.ul>
										</motion.li>
									)))}
								</div>
							</div>
							<div className={styles.bottom}>
								<div></div>
								<div className={styles.nav}>
									<MenuType menu={menu} />										
								</div>
								<ul className={styles.menu}>
									<li
										className={styles.cartIconPoints}
									>
										{user?.points > 0 && <span><BiWalletAlt />{currencyMask(user?.points)}</span>}
										<AiOutlineUser onClick={() => doLogin()} />
									</li>

									{user?.email === "paulo@pippa.com.br" && (
										<li>
											<GiClothes onClick={() => router.push("/revender")} />
										</li>
									)}

									<li onClick={() => setSearchBar(true)} aria-label="Busca">
										<BiSearch />
									</li>

									<li>
										<a href="/lista-de-desejos" aria-label="Lista de Desejo">
											<AiOutlineHeart />
										</a>									
									</li>
									
									<li
										className={styles.cartIcon}
									>
										{/* <a href="/carrinho" aria-label="Carrinho"> */}
											<BsBag onClick={() => setIsOpenCart(true)} />
											<span aria-hidden="true">{cart?.items?.length || 0}</span>
										{/* </a> */}
									</li>
								</ul>
							</div>
							<ul className={styles.mobileMenu}>
								<li>
									<img
										onClick={() => setIsMobileMenuOpen(true)}
										className={styles.mobileMenuNav}
										src="/assets/icons/bars-solid.svg"
										alt=""
									/>
									<a href="/" aria-label={account?.name}>
										<img className={styles.mobileHeaderLogo + ' logo'}
											src="/assets/logo.png"
											alt={account?.name}
										/>
									</a>
								</li>
								<li
									className={styles.mobileSearch}
									onClick={() => setSearchBar(true)}>
									<BiSearch />
								</li>
								<li
									className={styles.mobileLogin}
									onClick={() => doLogin()}>
									<BsPerson />
								</li>
								<li
									className={styles.mobileCart}
								>
									{/* <a href="/carrinho" aria-label="Carrinho"> */}
										<BsBag onClick={() => setIsOpenCart(true)} />
										<span aria-hidden="true">{cart?.items?.length || 0}</span>
									{/* </a> */}
								</li>
							</ul>
						</div>
					</>
				}
			</div>
			
			{/* <div className={styles.headerSticky + ' ' + styles[stickyHeader]}> */}
			<div className={`${stickyHeaderNone === "none" ? styles.headerInitial : styles.headerSticky + ' ' + styles[stickyHeader] + ' ' + (THEME_SETTING.disabledHeaderInline ? styles.headerStickyFixed  : styles.headerNormalFixed)}`}>
			{/* <div className={styles.headerSticky + ' ' + styles[stickyHeader] + ' ' + (THEME_SETTING.disabledHeaderInline ? styles.headerStickyFixed  : styles.headerNormalFixed)}> */}
				
				{topHeader?.published === true &&
					<div className={styles.topHeaderMobile}>
						<CarouselTopHeader topHeader={topHeader} />
					</div>
				}

				<div className={styles.content}>
					<div>
						<a href="/" aria-label={account?.name}>
							<img className={styles.logo + ' logo'}
								 alt={account?.name} 
								 src="/assets/logo.png"/>
						</a>
					</div>
					<div className={styles.nav}>
						<MenuType menu={menu} />						
					</div>
					<ul className={styles.menu}>
						<li
							className={styles.cartIconPoints}
						>
							{user?.points > 0 && <span><BiWalletAlt />{currencyMask(user?.points)}</span>}
							<AiOutlineUser onClick={() => doLogin()} />
						</li>

						{user?.email === "paulo@pippa.com.br" && (
							<li>
								<GiClothes onClick={() => router.push("/revender")} />
							</li>
						)}
						<li onClick={() => setSearchBar(true)}>
							<BiSearch />
						</li>
						<li>
							<a href="/lista-de-desejos" aria-label="Lista de Desejo">
								<AiOutlineHeart />
							</a>							
						</li>
						<li
							className={styles.cartIcon}
						>
							{/* <a href="/carrinho" aria-label="Carrinho"> */}
								<BsBag onClick={() => setIsOpenCart(true)} />
								<span
									aria-hidden="true"
									onClick={() => setIsOpenCart(true)}
								>
									{cart?.items?.length || 0}
								</span>
							{/* </a> */}
						</li>
						{/* <li onClick={() => doLogin()}>
							<AiOutlineUser />
						</li> */}
					</ul>
					<div className={styles.mobileMenu}>
						<div className={styles.left}>
							<img
								onClick={toggleDrawer}
								className={styles.mobileMenuNav}
								src="/assets/icons/bars-solid.svg"
								alt=""
							/>							
						</div>
						<div className={styles.center}>						
							<a href="/" aria-label={account?.name}>
								<img
									className={styles.mobileHeaderLogo + ' logo'}
									src="/assets/logo.png"
									alt={account?.name}
								/>
							</a>
						</div>
						<ul className={styles.right}>
							<li
								className={styles.mobileIconPoints}
							>
								{user?.points > 0 && <span><BiWalletAlt />{currencyMask(user?.points)}</span>}
								<AiOutlineUser onClick={() => doLogin()} />
							</li>
							<li
								className={styles.mobileSearch}
								onClick={() => setSearchBar(true)}>
								<BiSearch />
							</li>
							{/* <li
								className={styles.mobileLogin}
								onClick={() => doLogin()}>
								<BsPerson />
							</li> */}
							<li
								className={styles.mobileCart}
							>
								{/* <a href="/carrinho" aria-label="Carrinho"> */}
									<BsBag onClick={() => setIsOpenCart(true)} />
									<span
										aria-hidden="true"
										onClick={() => setIsOpenCart(true)}
									>
										{cart?.items?.length || 0}
									</span>
								{/* </a> */}
							</li>
						</ul>
					</div>
				</div>
			</div>

			{/* {isHeaderMenuOpen && ( */}
				<MobileMenu
					menu={menu}
					categories={categories}
					setIsOpen={toggleDrawer}
					isOpen={isHeaderMenuOpen}
					handleModal={setIsMobileMenuOpen}
				/>
			{/* )} */}

			<AnimatePresence
				initial={false}
				mode='wait'
				onExitComplete={() => null}
			>
				{searchBar && <SearchBar setSearchBar={setSearchBar} />}
        		{isOpenCart && <ModalBag setModalBag={setIsOpenCart} />}
			</AnimatePresence>
		</>
	);
};
