import styles from "./styles.module.scss";
import { InvalidEvent } from "react";
import { useForm } from "react-hook-form";
import { AUTH_SETTING, CART_SETTING } from "../../setting/setting";
import { toast } from "react-hot-toast";
import ErrorMessage from "../../component-nextv3/ErrorMessage";
import { addUserAuth, loginAuth } from "../../core-nextv3/auth/auth.api";
import { mergeCart } from "../../core-nextv3/cart/cart.api";
import { useCore } from "../../core-nextv3/core/core";
import { getRecaptcha } from "@/core-nextv3/util/util";

export const Newsletter = ({ account }: any) => 
{
  const { awaitLoadingHTTP, setAwaitLoadingHTTP, cart, setUser } = useCore();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = async (data: any) => 
  {
    const token = await getRecaptcha("addUser");

		if(token) 
    {
          setAwaitLoadingHTTP(true);

          // CADASTRO
          const newData = {
              token : token,
              data: {
                  name	   : data.name,
                  email	   : data.email.toLowerCase(),
                  password : data.email.toLowerCase()
              },
          };	

          const result = await addUserAuth(AUTH_SETTING.merge(newData));
            
          // CADASTRO NOVO FAZ LOGIN
          if(result.status) 
          {
              const token = await getRecaptcha("login");

              // LOGIN
              const result2 = await loginAuth(AUTH_SETTING.merge({
                  login	   : data.email.toLowerCase(),
                  password : data.email.toLowerCase(),
                  token    : token
              }));

              // MERGE CART SESSION
              await mergeCart(CART_SETTING.merge({
                  document : {
                      referencePath : cart?.referencePath
                  }
              }));

              setUser(result2.data);
          }

          reset();

          setAwaitLoadingHTTP(false);
          menssageEmailSuccess();
      }	                      
  };

  async function menssageEmailSuccess() {
    toast(
      (t) => (
        <span>
          <p>Cadastro feito com sucesso!</p>
          <button onClick={() => toast.dismiss(t.id)}>Ok</button>
        </span>
      ),
      {
        className: 'toast-custom',
        duration: 999999,
      }
    );

  };

  return (
    <section className={styles.newsletter}>
      <h1>Newsletter</h1>
      <p>Cadastre-se para receber nossas novidades.</p>
      <form id="newsletter" onSubmit={(e) => e.preventDefault()}>
        <div className={styles.inputItem}>
          <input
            type="text"
            placeholder="Nome"
            required
            onInvalid={(e: InvalidEvent<HTMLInputElement>) =>
              e.target.setCustomValidity("Preencha seu nome aqui!")
            }

            onInput={(e: InvalidEvent<HTMLInputElement>) =>
              e.target.setCustomValidity("")
            }

            {...register("name", {
              required: "Este Campo é Obrigatório",
              validate: (value) =>
                value.length >= 1 ||
                "Seu nome deve possuir 8 caracteres no mínimo!",
            })}
          />
          {errors.name && <ErrorMessage message={errors.name.message} />}
        </div>

        <div className={styles.submit}>
          {/* <div className={styles.inputItem}> */}
            <input
              type="email"
              required
              placeholder="E-mail"
              onInvalid={(e: InvalidEvent<HTMLInputElement>) =>
                e.target.setCustomValidity("Insira um email válido!")
              }

              onInput={(e: InvalidEvent<HTMLInputElement>) =>
                e.target.setCustomValidity("")
              }

              {...register("email", {
                required: "Este Campo é Obrigatório",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Email inválido",
                },
              })}
            />
            {errors.email && <ErrorMessage message={errors.email.message} />}
          {/* </div> */}
          <button
            type="submit"
            form="newsletter"
            onClick={handleSubmit(onSubmit)}
          >
            Ok
          </button>
        </div>
      </form>
    </section>
  );
};
