import "../styles/globals.scss";

import { AppPage } from "../varejo-nextv3/AppPage";
import { AppPageTwo } from "../varejo-nextv3/AppPageTwo";

function MyApp({ Component, pageProps }: any) 
{
	return (
		<AppPageTwo Component={Component} pageProps={pageProps} />	
	);
}

export default MyApp;
 