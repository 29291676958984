// import { Options, Splide, SplideSlide } from "@splidejs/react-splide";
// import styles from "./styles.module.scss";

// export const CarouselTopHeader = ({ topHeader }: any) => {

//   const mainOptions : Options = {
//       role       : "banner",
//       rewind     : true,
//       type       : 'loop',
//       easing     : 'ease',
//       perPage    : 1,
//       perMove    : 1,
//       width      : '100vw',
//       pagination : false,
//       slideFocus : true,
//       start      : 0,
//       arrows     : false,
//       autoplay   : true,
//       interval   : 3000,
//   };

//   return (
//     <div className={styles.carouselTopHeader + ' carouselTopHeader'}>
//       <div className={styles.content}>
//         <Splide
//               options={mainOptions}     
//               aria-label="Banner"
//             >
//               {topHeader?.list?.map((item:any, index:any) => (
//                 <div key={index} className="splide__slide">
//                     <div className={styles.topHeaderSlide}>
//                         <p>{item?.name}</p>
//                     </div>                  
//                 </div>
//               ))}
//           </Splide>        
//       </div>
//     </div>
//   );
// };

import styles from "./styles.module.scss";
import { useState } from "react";
import { BsPause, BsPlay } from "react-icons/bs";

export const CarouselTopHeader = ({ topHeader }: any) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(true);

  return (
    <section className={styles.fadeInContent}>
      {/* {isPlaying ? (
        <BsPause onClick={() => setIsPlaying(!isPlaying)} />
      ) : (
        <BsPlay onClick={() => setIsPlaying(!isPlaying)} />
      )} */}

      <div className={styles.features}>
        <div className={`${styles.marquee} ${!isPlaying && styles.pause}`}>
          {topHeader?.list?.map((item: any, index: any) => (
            <div className={styles.item} key={index}>
              <p>
                {item?.name}
              </p>
              <span>|</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};