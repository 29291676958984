import { setting } from "../core-nextv3/util/setting";

const platform = {
	id    : "hlvsk9bS",
	label : "E-commerce",
	value : "ecommerce"
}

const THEME_SETTING = {
	noStock: false,
	disabledFeatures : false,
	disabledHeaderInline : true,
	disabledHeaderSticke : true,
	disabledCarouselPhotosSmall : true,
	disabledIconWhatsAppPageProduct : false,
	carouselImagesSticky : true,
	pixDiscount : 5,
	disabledDisplayColorVatiant : true,
	aspectRatio : 607 / 911,
	bannerAspectRatio : 1920 / 813,
	disabledFotoCategoryMobile : false,
	disabledPromotionalPercentage : false,
	perPage															 : 40,
	disabledInfiniteScroll				: true,
	productPath : '/produto/',
	typeColorVariantImage : "square", //circle or square
	maxParcel : 6,
	minValue : 30,
	revalidate : 60 * 30,
	footerInfo : '<p>© 2024 Todos os direitos reservados à Nury Indústria e Comércio de Roupas LTDA</p><p>CNPJ: 43.713.477/0001-27</p><p>Rua dos Italianos, 107 - 2º andar - Bom Retiro, São Paulo - SP, 01131-000</p>',
	sacInfo1   : '<p>Atendimento sobre ao seu pedido ou devolução.</p><p>Email: sac@usenoura.com.br<br/>Whatsapp: (11) 94335-6879</p><p>Segunda à Sexta das 8h às 17h, exceto feriados.</p>',
	cookieConsent : `Olá cliente Noura, utilizamos cookies com fins analíticos e para mostrar anúncios relacionados a suas preferências. Para mais informações visite a nossa <a href="/loja/politica-de-privacidade/" style={{ fontSize: "14px" }}>Política de Privacidade</a>`,
};

const parseSizeVariant = (label:string) =>
{
	return label.split(' / ')[0];
}

// MENU HEADER
const MENU_HEADER_STATIC = [{
	label : 'Como Comprar',
	url   : '/loja/como-comprar',
},
{
	label : 'Dúvidas ?',
	url   : '/loja/duvidas',
}]

// MENU FOOTER
const MENU_FOOTER_STATIC = [{
	label : 'Institucional',
	children : [{
		label : 'Sobre Nós',
		url   : '/loja/sobre-nos/',
	},
	{
		label : 'Trocas e Devoluções',
		url   : '/loja/troca-e-devolucao/',
	},
	{
		label : 'Pagamentos e Envios',
		url   : '/loja/pagamentos-e-envios/',
	},
	{
		label : 'Privacidade e Segurança',
		url   : '/loja/politica-de-privacidade/',
	}]
},
{
	label : 'Minha Conta',
	children : [{
		label : 'Meus Dados',
		url   : '/perfil/detalhes-da-conta/',
	},
	{
		label : 'Meus Pedidos',
		url   : '/perfil/',
	},
	{
		label : 'Lista de Desejos',
		url   : '/lista-de-desejos/',
	}]
}];

// ACCOUNT
const ACCOUNT_SETTING = setting({
	accid : "default",
	appid: "accounts",
	colid: "documents",
	platform: platform,
	orderBy: "name",
	map: true,
	mapItems: {
	  	referencePath: "default/accounts/grids/5vTO1pyeQ1DrUjtmzOTx",
	},
});

// CATEGORY
const CATEGORY_SETTING = setting({
	accid: "default",
	appid: "category",
	colid: "documents",
	platform: platform,
	orderBy: "name",
	map: true,
	mapItems: {
	  	referencePath: "default/category/grids/6diDjwiaSnSRWAsxwiL8",
	},
});

const SLUG_CATEGORY_SETTING = setting({
	accid: "default",
	appid: "category",
	colid: "documents",
	platform: platform,
	orderBy: "name",
	map: true,
	mapItems: {
	  	referencePath: "default/category/grids/aCDBBWPiKheOPVYHAAmM",
	},
});

// MENU
const MENU_SETTING = setting({
	accid: "default",
	appid: "menuEcom",
	colid: "documentsMenu",
	platform: platform,
	orderBy: "order",
	perPage : 80,
	map: true,
	mapItems: {
	  	referencePath: "default/menuEcom/grids/dqLTDidonpYYJHQbxzUf",
	},
});

// COLLECTION
const COLLECTION_SETTING = setting({
	accid: "default",
	appid: "collection",
	colid: "documentsCollection",
	platform: platform,
	orderBy: "order",
	map: true,
	level:1,
	mapItems: {
	  	referencePath: "default/collection/grids/nWV3sEDHGNyJ39fbijkm",
	},
	stockName: {
		referencePath: "default/stockName/documents/H7ny9BhRSF6jj1KsfeDW",
	},
	priceName: {
		referencePath: "default/priceName/documents/IHB4sw0sMlKnyipukzBl",
	},
});

const SLUG_COLLECTION_SETTING = setting({
	accid: "default",
	appid: "collection",
	colid: "documentsCollection",
	platform: platform,
	orderBy: "order",
	map: true,
	level:1,
	mapItems: {
	  	referencePath: "default/collection/grids/pmLOGyNNcBXgZmdxpfaA",
	},
	stockName: {
		referencePath: "default/stockName/documents/H7ny9BhRSF6jj1KsfeDW",
	},
	priceName: {
		referencePath: "default/priceName/documents/IHB4sw0sMlKnyipukzBl",
	},
});

//PAGE
const PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/iBqwfjj1mYhVKHRnUuTn",
	},
});

//COLLECTION PAGE
const COLLECTION_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/3nPSpGQi6NnHCr8irdE3",
	},
	document: {
		referencePath: "default/page/documents/IuzEywvzHErhbvGYoIgQ",
	},
});

// TROCA PAGE
const TROCA_PAGE_SETTING = PAGE_SETTING.merge(
{
	document: {
		referencePath: "default/page/documents/HP1sNyqv7m5aWMcags67",
	},
})

// SALE PAGE
const SALE_PAGE_SETTING = PAGE_SETTING.merge(
{
	document: {
		referencePath: "default/page/documents/vAhuufrUifjQNPXycCTt",
	},
})

//HOME PAGE
const HOME_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems : {
		referencePath: "default/page/grids/yRP915cQD7mknZnQfaTR",
	},
	stockName: {
		referencePath: "default/stockName/documents/H7ny9BhRSF6jj1KsfeDW",
	},
	priceName: {
		referencePath: "default/priceName/documents/IHB4sw0sMlKnyipukzBl",
	},
	id : "x7QfWZRN8FUuJM4OtlaN",
});

// PAGE PIX
const PAGE_PIX_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/a04qis2fVNOi4VKwjEjI",
	},
	document: {
		referencePath: "default/page/documents/qkxMIJJolEzWcnRC8FR7",
	},
});

//PAGE DEALER
const PAGE_SELLER_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/a3mTvxKTrSmvKvyyRxV2",
	},
	document: {
		referencePath: "default/page/documents/EJEyLwpZwwfD2EC3heQ5"
	}
});

//ORDER
const ORDER_SETTING = setting({
	accid: "default",
	appid: "financial",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	form: {
        referencePath: "default/financial/forms/yBwJpLgkPRGiJcjVlz4r",
	},
	mapItems: {
		referencePath: "default/financial/grids/Pxn8ESNxcjMqviSVw7KN",
	},
	stockName: {
		referencePath: "default/stockName/documents/H7ny9BhRSF6jj1KsfeDW",
	},
	priceName: {
		referencePath: "default/priceName/documents/IHB4sw0sMlKnyipukzBl",
	},
});

//ORDER EXPRESS
const ORDER_EXPRESS_SETTING = setting({
	accid: "default",
	appid: "orderExpress",
	colid: "documentsOrderExpress",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	form: {
        referencePath: "default/orderExpress/forms/OUYzb934xB50JmpgQK2C",
	},
	mapItems: {
		referencePath: "default/orderExpress/grids/rR5phBwht0EmcaWH3fMZ",
	},
	stockName: {
		referencePath: "default/stockName/documents/H7ny9BhRSF6jj1KsfeDW",
	},
	priceName: {
		referencePath: "default/priceName/documents/IHB4sw0sMlKnyipukzBl",
	},
});

//FEATURES PRODUCTS
const FEATRES_PRODUCTS_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems : {
		referencePath: "default/page/grids/D4V0jcTeY8n8XnK8XdFa",
	},
	document: {
		referencePath: "default/page/documents/d8xzjdlfzk74QHJ9l76a",
	}
});

//REORDER
const REORDER_SETTING = setting({
	accid: "default",
	appid: "reorder",
	colid: "documentsReorder",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	reorderAmount : 1,
	ownerField : 'client',
	form: {
        referencePath: "default/reorder/forms/H8Y34pKpwr2Be83hmiYY",
	},
	mapItems: {
		referencePath: "default/reorder/grids/DUQEbmMvJyC3SIePsNPM",
	}
});

//COLOR VARIANT
const COLOR_VARIANT_SETTING = setting({
	accid: "default",
	appid: "variant",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/variant/grids/8fK0IK1Lfo5Jg0gEIG2T",
	},
	document : {
		referencePath: "default/variant/documents/LQ7n5VZ77ssTwNnfvx4l",		
	}
});

//SIZE VARIANT
const SIZE_VARIANT_SETTING = setting({
	accid: "default",
	appid: "variant",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/variant/grids/8fK0IK1Lfo5Jg0gEIG2T",
	},
	document : {
		referencePath: "default/variant/documents/ToPsCD1W8453TdFmi2OJ",		
	}
});

//FULL PRODUCT
const FULL_PRODUCT_SETTING = setting({
	accid: "default",
	appid: "product",
	colid: "documents",
	level: 1,
	map: true,
	asc: false,
	orderBy: "postdate",
	stockName: {
		referencePath: "default/stockName/documents/H7ny9BhRSF6jj1KsfeDW",
	},
	priceName: {
		referencePath: "default/priceName/documents/IHB4sw0sMlKnyipukzBl",
	},
	mapItems: {
		referencePath: "default/product/grids/ZUnonLswnK67KE4lnDmg",
	},
});

//PARTIAL PRODUCT
const PARTIAL_PRODUCT_SETTING = setting({
	accid: "default",
	appid: "product",
	colid: "documents",
	level: 1,
	map: true,
	asc: false,
	orderBy: "postdate",
	stockName: {
		referencePath: "default/stockName/documents/H7ny9BhRSF6jj1KsfeDW",
	},
	priceName: {
		referencePath: "default/priceName/documents/IHB4sw0sMlKnyipukzBl",
	},
	mapItems: {
		referencePath: "default/product/grids/10BQpGxcAxXgmYKqg0nD",
	},
});

//SLUG PRODUCT
const SLUG_PRODUCT_SETTING = setting({
	accid : "default",
	appid : "product",
	colid : "documents",
	level : 1,
	map : true,
	asc : false,
	orderBy : "postdate",
	mapItems : {
		referencePath: "default/product/grids/KDITs2CX8HKvsZ2tr58B",
	},
});

const PARTIAL_PRODUCT_VARIANT_SETTING = setting({
	accid: "default",
	appid: "productsVariant",
	colid: "documentsProductsVariant",
	level: 1,
	map: true,
	asc: false,
	orderBy: "postdate",
	stockName: {
		referencePath: "default/stockName/documents/H7ny9BhRSF6jj1KsfeDW",
	},
	priceName: {
		referencePath: "default/priceName/documents/IHB4sw0sMlKnyipukzBl",
	},
	mapItems: {
		referencePath: "default/productsVariant/grids/ZcmwVS3S0mCF3rz4krXe",
	},
});

//CART
const CART_SETTING = setting({
	accid: "default",
	appid: "cart",
	colid: "documents",
	platform: platform,
	map: true,
	form: {
		referencePath: "default/cart/forms/yi8sxDEbExlshNIXREV3",
	},
	mapItems: {
		// referencePath: "default/cart/grids/E2VYLKMKhIa3DkPfZsHn",
		referencePath: "default/cart/grids/PHQMj7Kv5ImTd7TW5RdT",
	},
	stockName: {
		referencePath: "default/stockName/documents/H7ny9BhRSF6jj1KsfeDW",
	},
	priceName: {
		referencePath: "default/priceName/documents/IHB4sw0sMlKnyipukzBl",
	},
});

// FAVORITE
const FAVORITE_SETTING = setting({
	accid: "default",
	appid: "favorite",
	colid: "documents",
	skuImage:true,
	platform: platform,
	orderBy : 'postdate',
	asc : false,
	form: {
	  	referencePath: "default/favorite/forms/ghuUb5rkraNVu4o3JfCm",
	},	
	map: true,
	mapItems: {
	  	referencePath: "default/favorite/grids/Acg4mXIZyUBv8FOYdUuI",
	},		
	stockName: {
		referencePath: "default/stockName/documents/H7ny9BhRSF6jj1KsfeDW",
	},
	priceName: {
		referencePath: "default/priceName/documents/IHB4sw0sMlKnyipukzBl",
	},
});

// RESALES
const RESALES_SETTING = setting({
	accid: "default",
	appid: "resales",
	colid: "documents",
	level:1,
	platform: platform,
	form: {
	  	referencePath: "default/resales/forms/3ZWFOUXqBaYABH7K4gyF",
	},	
	map: true,
	mapItems: {
	  	referencePath: "default/resales/grids/eD82vj8pyGFdbsU0EXv3",
	},		
	stockName: {
		referencePath: "default/stockName/documents/H7ny9BhRSF6jj1KsfeDW",
	},
	priceName: {
		referencePath: "default/priceName/documents/IHB4sw0sMlKnyipukzBl",
	},
});

// AUTH
const AUTH_SETTING = setting({
	accid: "default",
	appid: "users",
	colid: "documents",
	platform: platform,
	form: {
		referencePath: "default/users/forms/5BMnB6DwQDHZ0ir8zatM",
	},
	map: true,
	mapItems: {
	  	referencePath: "default/users/grids/AoCSINcZsgY9IPEFUkWg",
	},	
});

// SELLER CONTACT
const SELLER_CONTACT_SETTING = setting({
	accid: "default",
	appid: "contactSeller",
	colid: "documents",
	platform: platform,
	form: {
		referencePath: "default/contactSeller/forms/zqGcqKLsmjT2iKzhvpfQ",
	},
	map: true,
	mapItems: {
	  	referencePath: "default/contactSeller/grids/1mYfJQ2w88Xhb4Lj6q9Q",
	},	
});

// OPTION
const OPTION_SETTING = setting({
	accid	 : "default",
	appid	 : "option",
	colid	 : "documents",
	platform : platform,	
	map: true,
	mapItems: {
	  	referencePath: "default/option/grids/eOSSt7GuN91qGN0sV97l",
	},	
});

// PAYMENT METHODS OPTION
const PAYMENT_METHODS_OPTION_SETTING = setting({
	accid	 : "default",
	appid	 : "option",
	colid	 : "documents",
	platform : platform,	
	document: {
		referencePath: "default/option/documents/kdEtgv8BEwVRju3W5gmD",
  	},
	map: true,
	mapItems: {
	  	referencePath: "default/option/grids/eOSSt7GuN91qGN0sV97l",
	},	
});

//GATEWAY
const GATEWAY_SETTING = setting({
	accid: "default",
	appid: "gateway",
	colid: "documents",
	platform: platform,
	document: {
	  referencePath: "default/gateway/documents/F2JEy0TFZjhd63XOCosX",
	},
	mapItems: {
		referencePath: "default/gateway/grids/Yo2kRJv67cayQZiH0pox",
  },
});

// BANNER
const BANNER_SETTING = setting({
	accid: "default",
	appid: "banner",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "order",
	mapItems: {
	  	referencePath: "default/banner/grids/tbdlFvLpACmtWj9u1dNh",
	},
}); 

// TRACK
const TRACK_SETTING = setting({
	accid: "default",
	appid: "shippings",
	colid: "documentsShippings",
	platform: platform,
	shipping : {
		referencePath : 'default/shippings/documentsShippings/N7ob3Qoeihx3gLbEMKXO'
	},
	mapItems: {
		referencePath: "default/accounts/grids/5vTO1pyeQ1DrUjtmzOTx",
	}
});

// ANALYTICS
const ANALYTICS_SETTING = setting({
	accid: "default",
	appid: "analytics",
	colid: "documents",
	platform: platform,
	form: {
	  	referencePath: "default/analytics/forms/Ri9eeK5NsSlGCEsq7eeJ",
	},	
	map: true,
	mapItems: {
	  	referencePath: "default/analytics/grids/RlHy1iwUHPjpmlGZ9Bh7",
	},		
});

//TOPHEADER
const TOP_HEADER_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/uORRtH39mWoINxzVm2k2",
	},
});

//TRACK BACK
const TRACK_BACK_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/OwFSt7QmIGJvuzGdfPYK",
	},
	document: {
		referencePath: "default/page/documents/8Ri9sSbPlyj6HnvfkQga"
	}
});

// ERRORS
const ERROS_SETTING = setting({
	accid: "default",
	appid: "erros",
	colid: "documentsErros",
	platform: platform,
	map: true,
	form: {
		referencePath: "default/erros/forms/sSv7Lj2MLdLLHA4Fzk2s",
	},
	mapItems: {
		referencePath: "default/erros/grids/JfyS5xC2lX6URK7MYrAb",
	},
});

const REORDER_TYPE_OPTION = OPTION_SETTING.merge({
	id : 'jwk0vI8LG7C4Dii1LvUI'
});

const REASONS_REORDER_OPTION = OPTION_SETTING.merge({
	id : 'rKAwVJI5a9hyGLJQd2AK'
});

const REORDER_INFO : any = {
	name 			: 'Solicitar Troca/Crédito',
	labelNoteClient : 'Motivo da troca',
};

//COMMET
const COMMET_SETTING = setting({
	accid: "default",
	appid: "comment",
	colid: "documents",
	platform: platform,
	orderBy: "postdate",
	asc:false,
	map: true,
	mapItems: {
		referencePath: "default/comment/grids/wWWXSHbu7xZJ8swlj0yf",
	},
	form: {
		referencePath: "default/comment/forms/RUHBFkTuTsKGOiVBbbVr",
	},
});

//COLOR_TABLE
const COLOR_TABLE_SETTING = setting ({
	accid: "default",
	appid: "colorTable",
	colid: "documentsColorTable",
	map: true,
	asc: false,
	orderBy: "postdate",
	document: {
		referencePath: "default/colorTable/documentsColorTable/X34DSa3vivx6tAM5kUsM",
	},
	mapItems: {
		referencePath: "default/colorTable/grids/mkv5zqiiE0tDO2pYiLsQ",
	},
});

// PAGE TROCA
const PAGE_REPLACEMENT_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/b0OVrlJYytty2kyv4mT1",
	},
	document: {
		referencePath: "default/page/documents/t5fqlvxv5FjuTuR1Qh92",
	},
});

export { 
	THEME_SETTING,
	TROCA_PAGE_SETTING,
	MENU_FOOTER_STATIC, 
	BANNER_SETTING, 
	ACCOUNT_SETTING, 
	PAGE_SETTING,
	COLLECTION_PAGE_SETTING,
	TRACK_SETTING, 
	MENU_HEADER_STATIC,
	ANALYTICS_SETTING,
	CATEGORY_SETTING, 
	COLOR_VARIANT_SETTING, 
	FAVORITE_SETTING,
	RESALES_SETTING,
	AUTH_SETTING,
	SELLER_CONTACT_SETTING,
	SIZE_VARIANT_SETTING, 
	PAYMENT_METHODS_OPTION_SETTING,
	CART_SETTING, 
	COLLECTION_SETTING,
	SLUG_COLLECTION_SETTING,
	FULL_PRODUCT_SETTING, 
	ORDER_SETTING, 
	OPTION_SETTING, 
	HOME_PAGE_SETTING,
	PAGE_PIX_SETTING,
	PARTIAL_PRODUCT_SETTING, 
	SLUG_PRODUCT_SETTING,
	SLUG_CATEGORY_SETTING,
	MENU_SETTING,
	PARTIAL_PRODUCT_VARIANT_SETTING,
	TOP_HEADER_PAGE_SETTING,
	REORDER_SETTING,
	REORDER_TYPE_OPTION,
	ORDER_EXPRESS_SETTING,
	ERROS_SETTING,
	REORDER_INFO,
	COMMET_SETTING,
	REASONS_REORDER_OPTION,
	COLOR_TABLE_SETTING,
	GATEWAY_SETTING,
	TRACK_BACK_PAGE_SETTING,
	PAGE_SELLER_SETTING,
	SALE_PAGE_SETTING,
	FEATRES_PRODUCTS_SETTING,
	PAGE_REPLACEMENT_SETTING,
	parseSizeVariant,
}